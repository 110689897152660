@font-face {
  font-family: "Work Sans";
  src: url("./WorkSans-Bold.ttf") format("truetype"),
    url("./WorkSans-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Work Sans";
  src: url("./WorkSans-BoldItalic.ttf") format("truetype"),
    url("./WorkSans-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Work Sans";
  src: url("./WorkSans-Medium.ttf") format("truetype"),
    url("./WorkSans-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Work Sans";
  src: url("./WorkSans-MediumItalic.ttf") format("truetype"),
    url("./WorkSans-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Work Sans";
  src: url("./WorkSans-Regular.ttf") format("truetype"),
    url("./WorkSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Work Sans";
  src: url("./WorkSans-Italic.ttf") format("truetype"),
    url("./WorkSans-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}
